var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "px-6 py-4" },
    [
      _c(
        "v-expansion-panels",
        {
          model: {
            value: _vm.currentQuestion,
            callback: function ($$v) {
              _vm.currentQuestion = $$v
            },
            expression: "currentQuestion",
          },
        },
        _vm._l(_vm.formData.questions, function (question, i) {
          return _c(
            "v-expansion-panel",
            { key: i },
            [
              _c(
                "v-expansion-panel-header",
                { staticClass: "expansion-header" },
                [
                  _c("v-col", { staticClass: "pa-0" }, [
                    _vm._v("Question " + _vm._s(i + 1) + " Details"),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "1" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              icon: "",
                                              color: "red accent-3",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteQuestion(i)
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("delete_outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                !_vm.$language
                                  ? "Delete question"
                                  : "প্রশ্ন ডিলিট করুন"
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                [
                  _c("single-question-form-details", {
                    ref: "detailsForm",
                    refInFor: true,
                    attrs: { "is-valid": _vm.questionOkArray[i] },
                    on: {
                      "update:isValid": function ($event) {
                        return _vm.$set(_vm.questionOkArray, i, $event)
                      },
                      "update:is-valid": function ($event) {
                        return _vm.$set(_vm.questionOkArray, i, $event)
                      },
                    },
                    model: {
                      value: question.questionDetails,
                      callback: function ($$v) {
                        _vm.$set(question, "questionDetails", $$v)
                      },
                      expression: "question.questionDetails",
                    },
                  }),
                  _c("v-divider", { staticClass: "mb-3" }),
                  question.questionDetails.questionType === "desc"
                    ? _c("single-question-form-answers-desc", {
                        ref: "answersForm",
                        refInFor: true,
                        attrs: { "is-valid": _vm.answerOkArray[i] },
                        on: {
                          "update:isValid": function ($event) {
                            return _vm.$set(_vm.answerOkArray, i, $event)
                          },
                          "update:is-valid": function ($event) {
                            return _vm.$set(_vm.answerOkArray, i, $event)
                          },
                        },
                        model: {
                          value: question.answerPreference,
                          callback: function ($$v) {
                            _vm.$set(question, "answerPreference", $$v)
                          },
                          expression: "question.answerPreference",
                        },
                      })
                    : _c("single-question-form-answers", {
                        ref: "answersForm",
                        refInFor: true,
                        attrs: {
                          "question-type":
                            question.questionDetails.questionType,
                          "is-valid": _vm.answerOkArray[i],
                        },
                        on: {
                          "update:isValid": function ($event) {
                            return _vm.$set(_vm.answerOkArray, i, $event)
                          },
                          "update:is-valid": function ($event) {
                            return _vm.$set(_vm.answerOkArray, i, $event)
                          },
                        },
                        model: {
                          value: question.answers,
                          callback: function ($$v) {
                            _vm.$set(question, "answers", $$v)
                          },
                          expression: "question.answers",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "m-row",
        { staticClass: "mt-5" },
        [
          _c(
            "m-col",
            [
              _c(
                "m-button",
                {
                  attrs: { outlined: "", rounded: "", color: "primary" },
                  on: { click: _vm.addQuestion },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("add")]),
                  _vm._v(
                    _vm._s(
                      !_vm.$language
                        ? "Add another Question"
                        : "আরেকটি প্রশ্ন যোগ করুন"
                    ) + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }