var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "px-7 py-4" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { value: _vm.isValid },
          on: {
            input: function ($event) {
              return _vm.$emit("update:is-valid", $event)
            },
          },
        },
        [
          _c(
            "m-row",
            { staticClass: "mt-3" },
            [
              _c(
                "m-col",
                { attrs: { cols: _vm.mobileScreen ? 12 : 5 } },
                [
                  _c("v-combobox", {
                    staticClass: "tag-select",
                    attrs: {
                      outlined: "",
                      multiple: "",
                      items: _vm.topics.filter((t) => t.shared === false),
                      label: !_vm.$language ? "Topic(s)" : "টপিক",
                      placeholder: !_vm.$language
                        ? "Enter one or multiple tags...(i.e. not more than 3 tags)"
                        : "এক বা একাধিক ট্যাগ লিখুন...(i.e. ৩টির বেশি ট্যাগ নয়)",
                      chips: "",
                      "deletable-chips": "",
                      "data-qa": "question-topic",
                      "append-icon": "",
                      rules: [_vm.atLeastOne, _vm.threeOrLess, _vm.textLength],
                    },
                    on: { input: _vm.enterTopicObject },
                    model: {
                      value: _vm.formData.topics,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "topics", $$v)
                      },
                      expression: "formData.topics",
                    },
                  }),
                ],
                1
              ),
              _c(
                "m-col",
                { attrs: { cols: _vm.mobileScreen ? 12 : 7 } },
                [_vm._t("top-right")],
                2
              ),
            ],
            1
          ),
          _c("div", { staticClass: "content-header mb-1" }, [
            _vm._v(
              " " +
                _vm._s(!_vm.$language ? "Essay Details" : "রচনা বিবরণ") +
                " "
            ),
          ]),
          _c(
            "p",
            {
              staticClass: "mb-8",
              style: _vm.mobileScreen ? `font-size: 14px;` : `font-size: 16px;`,
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    !_vm.$language
                      ? "Note: “Group Question” allows you to create a preamble of text, images, videos and audios, followed by a set of questions."
                      : "বি:দ্র: কম্প্রিহেন্সিভ প্রশ্ন দ্বারা আপনি প্রশ্ন সেট এর মাধ্যমে পাঠ্যের এক বা একধিক অনুচ্ছেদ তৈরী করতে পারবেন।"
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("tip-tap-editor", {
                    staticClass: "rounded",
                    attrs: {
                      label: "Preamble",
                      "allow-list-insertion": "",
                      "allow-media-insertion": "",
                      "allow-table-insertion": "",
                      "is-valid": !_vm.essayLengthExceeded,
                    },
                    on: { "content-update": _vm.updateEssayContent },
                    model: {
                      value: _vm.essayContent,
                      callback: function ($$v) {
                        _vm.essayContent = $$v
                      },
                      expression: "essayContent",
                    },
                  }),
                  _vm.essayContent
                    ? _c("div", [
                        _vm.essayLengthExceeded
                          ? _c(
                              "div",
                              {
                                staticClass: "essay-length",
                                staticStyle: { color: "#e80000" },
                              },
                              [
                                _vm._v(
                                  " Length: " +
                                    _vm._s(
                                      _vm.essayContent.htmlToText().length
                                    ) +
                                    "/10000 (must not exceed 10000) "
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "essay-length",
                                staticStyle: { color: "grey" },
                              },
                              [
                                _vm._v(
                                  " Length: " +
                                    _vm._s(
                                      _vm.essayContent.htmlToText().length
                                    ) +
                                    "/10000 "
                                ),
                              ]
                            ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }