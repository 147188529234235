<template>
  <m-container class="px-7 py-4">
    <v-form
      ref="form"
      :value="isValid"
      @input="$emit('update:is-valid', $event)"
    >
      <m-row class="mt-3">
        <m-col :cols="mobileScreen ? 12 : 5">
          <v-combobox
            v-model="formData.topics"
            outlined
            multiple
            :items="topics.filter(t => t.shared === false)"
            :label="!$language ? 'Topic(s)' : 'টপিক'"
            :placeholder="
              !$language
                ? 'Enter one or multiple tags...(i.e. not more than 3 tags)'
                : 'এক বা একাধিক ট্যাগ লিখুন...(i.e. ৩টির বেশি ট্যাগ নয়)'
            "
            chips
            deletable-chips
            data-qa="question-topic"
            class="tag-select"
            append-icon=""
            :rules="[atLeastOne, threeOrLess, textLength]"
            @input="enterTopicObject"
          >
          </v-combobox>
        </m-col>
        <m-col :cols="mobileScreen ? 12 : 7">
          <slot name="top-right"></slot>
        </m-col>
      </m-row>

      <div class="content-header mb-1">
        {{ !$language ? "Essay Details" : "রচনা বিবরণ" }}
      </div>
      <p
        class="mb-8"
        :style="mobileScreen ? `font-size: 14px;` : `font-size: 16px;`"
      >
        {{
          !$language
            ? "Note: “Group Question” allows you to create a preamble of text, images, videos and audios, followed by a set of questions."
            : "বি:দ্র: কম্প্রিহেন্সিভ প্রশ্ন দ্বারা আপনি প্রশ্ন সেট এর মাধ্যমে পাঠ্যের এক বা একধিক অনুচ্ছেদ তৈরী করতে পারবেন।"
        }}
      </p>
      <!--      keeping the following line for future possible debugging purpose-->
      <!--      <div class="mb-4">{{ essayContent }}</div>-->
      <v-row>
        <v-col>
          <tip-tap-editor
            v-model="essayContent"
            class="rounded"
            label="Preamble"
            allow-list-insertion
            allow-media-insertion
            allow-table-insertion
            :is-valid="!essayLengthExceeded"
            @content-update="updateEssayContent"
          ></tip-tap-editor>
          <div v-if="essayContent">
            <div
              v-if="essayLengthExceeded"
              class="essay-length"
              style="color: #e80000;"
            >
              Length: {{ essayContent.htmlToText().length }}/10000 (must not
              exceed 10000)
            </div>
            <div v-else class="essay-length" style="color: grey;">
              Length: {{ essayContent.htmlToText().length }}/10000
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </m-container>
</template>

<script>
import { TopicsProvider } from "#ecf/question-bank/mixins";
import TipTapEditor from "@ecf/global/components/TipTapEditor";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "GroupQuestionFormEssay",
  components: { TipTapEditor },
  mixins: [TopicsProvider, FieldValidations],
  model: {
    prop: "essay"
  },
  props: {
    essay: {
      type: Object,
      required: true
    },
    isValid: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (!this.essayContent) {
      this.essayContent = this.essay.text;
    }
  },
  data() {
    return {
      essayContent: undefined,
      essayContentWithMedia: undefined,
      imageValidationMessage: undefined,
      formData: { ...this.essay },
      isTopicFormVisible: false,
      rules: {
        required: [this.required],
        imageRules: [
          v =>
            this.fileSizeLessThanOrEqual(
              v,
              5 * 1024 * 1024,
              "Question Image Size"
            )
        ]
      }
    };
  },
  computed: {
    mobileScreen() {
      return this.$vuetify.breakpoint.width <= 360;
    },
    essayLengthExceeded() {
      if (!this.essayContent) return false;
      return this.essayContent.htmlToText().length > 10000;
    },
    imageFileName() {
      return this.formData.image.file
        ? this.formData.image.file.name
        : !this.$language
        ? "No Image Selected"
        : "কোন ছবি নির্বাচন করা হয়নি";
    }
  },
  watch: {
    formData: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    updateEssayContent(value) {
      this.essayContentWithMedia = value.valueWithMediaBlobUrls;
      this.$emit("essay-update", value);
    },
    valid_image(value) {
      this.imageValidationMessage = value;
    },
    enterTopicObject() {
      this.formData.topics = this.formData.topics.map(topic => {
        if (typeof topic === "string") {
          return { text: topic };
        } else {
          return topic;
        }
      });
    },
    atLeastOne(value) {
      return this.required(value.length > 0);
    },
    threeOrLess(value) {
      return this.maxValue(value.length, 3, "Tags attached");
    },
    textLength(value) {
      let errMsg = "";
      value.forEach(element => {
        errMsg = this.betweenValue(
          element.text.length,
          3,
          50,
          true,
          "Question tag length"
        );
      });
      return errMsg;
    },
    updateTags() {
      this.$nextTick(() => {
        this.formData.topics.push(...this.search.split(","));
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    clearImage() {
      this.formData.image = undefined;
    }
  }
};
</script>

<style lang="scss">
.essay-length {
  font-size: 12px;
  margin-left: 6px;
  margin-top: 6px;
}
</style>
