<template>
  <m-container class="px-6 py-4">
    <v-expansion-panels v-model="currentQuestion">
      <v-expansion-panel v-for="(question, i) in formData.questions" :key="i">
        <!--        need to add a Question counter.-->
        <v-expansion-panel-header class="expansion-header">
          <v-col class="pa-0">Question {{ i + 1 }} Details</v-col>
          <v-col cols="1" class="pa-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red accent-3"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteQuestion(i)"
                  ><v-icon>delete_outline</v-icon></v-btn
                >
              </template>
              <span>{{
                !$language ? "Delete question" : "প্রশ্ন ডিলিট করুন"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <single-question-form-details
            ref="detailsForm"
            v-model="question.questionDetails"
            :is-valid.sync="questionOkArray[i]"
          ></single-question-form-details>
          <v-divider class="mb-3"></v-divider>
          <single-question-form-answers-desc
            v-if="question.questionDetails.questionType === 'desc'"
            ref="answersForm"
            v-model="question.answerPreference"
            :is-valid.sync="answerOkArray[i]"
          ></single-question-form-answers-desc>
          <single-question-form-answers
            v-else
            ref="answersForm"
            v-model="question.answers"
            :question-type="question.questionDetails.questionType"
            :is-valid.sync="answerOkArray[i]"
          ></single-question-form-answers>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <m-row class="mt-5">
      <m-col>
        <m-button outlined rounded color="primary" @click="addQuestion"
          ><v-icon left>add</v-icon
          >{{ !$language ? "Add another Question" : "আরেকটি প্রশ্ন যোগ করুন" }}
        </m-button>
      </m-col>
    </m-row>
  </m-container>
</template>

<script>
import SingleQuestionFormAnswers from "#ecf/question-bank/components/SingleQuestion/FormAnswers";
import SingleQuestionFormDetails from "#ecf/question-bank/components/SingleQuestion/FormDetails";
import SingleQuestionFormAnswersDesc from "#ecf/question-bank/components/SingleQuestion/FormAnswersDesc";

export default {
  name: "GroupQuestionFormQuestionSet",
  components: {
    SingleQuestionFormAnswers,
    SingleQuestionFormDetails,
    SingleQuestionFormAnswersDesc
  },
  model: {
    prop: "questions"
  },
  props: {
    topics: Array,
    questions: {
      type: Array,
      default: () => []
    },
    isValid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: { questions: [...this.questions] },
      currentQuestion: 0,
      questionOkArray: [],
      answerOkArray: []
    };
  },
  computed: {
    imageFileName() {
      return this.formData.image
        ? this.formData.image.file.name
        : "No Image Selected";
    }
  },
  inject: ["$courseId"],
  watch: {
    formData: {
      deep: true,
      handler(value) {
        this.$emit("input", value.questions);
      }
    },
    questionOkArray: {
      handler() {
        this.updateQAOkayArray();
      }
    },
    answerOkArray: {
      handler() {
        this.updateQAOkayArray();
      }
    }
  },
  created() {
    if (this.questions.length === 0) {
      this.addQuestion();
    } else {
      this.questions.forEach(item => {
        // during edit question, initially all question answer are valid
        this.questionOkArray.push(true);
        this.answerOkArray.push(true);
      });
      this.$emit("update:q-ok", true);
    }
  },
  methods: {
    updateQAOkayArray() {
      let quesAnsArrayOkay = true;
      for (let i = 0; i < this.formData.questions.length; i++) {
        quesAnsArrayOkay =
          quesAnsArrayOkay && this.questionOkArray[i] && this.answerOkArray[i];
        if (quesAnsArrayOkay === false) break;
      }
      this.$emit("update:is-valid", quesAnsArrayOkay);
    },
    deleteQuestion(i) {
      this.formData.questions.splice(i, 1);
      this.questionOkArray.splice(i, 1);
      this.answerOkArray.splice(i, 1);
      this.updateQAOkayArray();
    },
    addQuestion() {
      this.questionOkArray.push(false);
      this.answerOkArray.push(false);
      this.$emit("update:q-ok", false);
      this.formData.questions.push({
        questionDetails: {
          questionType: "mcq",
          image: { imageURL: null, file: null }
        },
        answers: [
          {
            text: undefined,
            is_correct: false,
            image: { imageURL: undefined, file: undefined },
            explanation: undefined
          }
        ],
        answerPreference: { word_limit: "100" },
        courseId: this.$courseId()
      });
    },
    validate(){
      this.$refs.detailsForm.forEach(item=>{
        item.validate();
      })
      this.$refs.answersForm.forEach(item=>{
        item.validate();
      })
    }
  }
};
</script>

<style lang="scss">
.v-expansion-panels {
  border-radius: 5px !important;
}
.expansion-header {
  font-weight: bold;
  color: $text;
  padding: 10px 20px !important;
}
</style>
